body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
  